import * as React from 'react';
import { Link } from 'gatsby';
import '../index.scss';
import {Dot, Logo} from "../components/Logo";
import {CHROME_WEBSTORE_LINK, FIREFOX_ADDONS_LINK} from '../utils/extension';
import {Page} from "../components/Page";
import {isFirefox} from "../utils/browser";


const GoodbyePage = () => {
  // TODO: clean this up into a hook
  const [browser, setBrowser] = React.useState<string>();
  React.useLayoutEffect(() => {
    if (isFirefox()) {
      setBrowser('Firefox');
    }
    else {
      setBrowser('Chrome');
    }
  }, []);

  return <Page title='Goodbye'>
    <section>
      <Link to='/' className='no-style'><Logo/></Link>
      <div className='spacer'/>
      <h1>Sorry to see you go<Dot/></h1>
      <p>If you didn't mean to uninstall the extension you can reinstall it <a href={browser === 'Firefox' ? FIREFOX_ADDONS_LINK : CHROME_WEBSTORE_LINK}
      >here</a>.</p>
    </section>
  </Page>
};

export default GoodbyePage;

import {isFirefox} from "./browser";

const CHROME_EXTENSION_ID_DEV = 'kkipnhfmgcmjdblkgpenikmaejdcgbgg'; // Local DEV versions
const CHROME_EXTENSION_ID = 'edcebjbpihembjfeogmjamkphfhldnln';

function sendMessageToExtension(message: any) {
  try {
    if (isFirefox()) {
      window.postMessage({ type: '_externalMessage', message }, '*');
    }
    else if (typeof chrome !== 'undefined' && chrome.runtime) { // not available on localhost
      chrome.runtime.sendMessage(CHROME_EXTENSION_ID, message, (response) => {
        console.log(response);
      });
      chrome.runtime.sendMessage(CHROME_EXTENSION_ID_DEV, message, (response) => {
        console.log(response);
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export function sendAuthenticateMessage(token: string) {
  sendMessageToExtension({
    type: 'authenticate',
    token,
  });
}

export const CHROME_WEBSTORE_LINK = 'https://chrome.google.com/webstore/detail/bluf/edcebjbpihembjfeogmjamkphfhldnln';
export const FIREFOX_ADDONS_LINK = 'https://addons.mozilla.org/en-US/firefox/addon/bluf/';

export const useExtensionLink = () => {
  return isFirefox() ? FIREFOX_ADDONS_LINK : CHROME_WEBSTORE_LINK;
}
